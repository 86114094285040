import React, { useState } from 'react';
import './styles.scss';
import { minutesToHours } from '../../utils/times';

const projectsTable = ({ items }) => {
  const [expandedProjects, setExpandedProjects] = useState([]);
  const projectsById = {};
  let totalMinutes = 0;
  const projectMap = {};

  items.forEach((item) => {
    const { project, person, minutes } = item;

    if (project.id in projectMap) {
      projectMap[project.id].totalMinutes += minutes;
      const personIndex = projectMap[project.id].people.findIndex(
        (p) => p.id === person.id,
      );

      if (personIndex !== -1) {
        projectMap[project.id].people[personIndex].minutes += minutes;
      } else {
        projectMap[project.id].people.push({
          id: person.id,
          firstName: person.firstName,
          lastName: person.lastName,
          minutes,
        });
      }
    } else {
      projectMap[project.id] = {
        id: project.id,
        projectName: project.name,
        totalMinutes: minutes,
        people: [
          {
            id: person.id,
            firstName: person.firstName,
            lastName: person.lastName,
            minutes,
          },
        ],
      };
    }
  });

  const projectArray = Object.values(projectMap);

  items.forEach((elem) => {
    totalMinutes += elem.minutes;
    if (!projectsById[elem.project.id]) {
      projectsById[elem.project.id] = {
        id: elem.project.id,
        name: elem.project.name,
        internal: elem.project.internal,
        minutes: 0,
        average: 0,
      };
    }
    projectsById[elem.project.id].minutes += elem.minutes;
  });

  const projects = Object.keys(projectsById).map((id) => {
    const newElem = projectsById[id];
    newElem.average = ((newElem.minutes * 100) / totalMinutes).toFixed(2);
    return newElem;
  }).sort((el1, el2) => {
    return el2.minutes - el1.minutes;
  });

  const handleClickDetail = (id) => {
    setExpandedProjects((prevState) => (prevState.includes(id)
      ? prevState.filter((proyectoId) => proyectoId !== id)
      : [...prevState, id]));
  };

  return (
    <div className="worked-times-projects-stats">
      <div className="table-container">
        <h2 className="title-table">Total de horas cargadas por proyecto</h2>
        <table>
          <thead>
            <tr>
              <th>Proyecto</th>
              <th>Horas</th>
              <th>Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((proyecto) => (
              <React.Fragment key={proyecto.id}>
                <tr>
                  <td>
                    <div className="project-cell">
                      {proyecto.internal ? (
                        <div className="project-tag project-internal" />
                      ) : (
                        <div className="project-tag project-external" />
                      )}
                      <button
                        type="button"
                        onClick={() => handleClickDetail(proyecto.id)}
                        className="expand-button"
                      >
                        {expandedProjects.includes(proyecto.id) ? '-' : '+'}
                      </button>
                      <span className="project-name">{proyecto.name}</span>
                    </div>
                  </td>
                  <td>
                    <span className="project-row">{minutesToHours(proyecto.minutes)}</span>
                  </td>
                  <td>
                    <span className="project-row">{`${proyecto.average} %`}</span>
                  </td>
                </tr>
                {expandedProjects.includes(proyecto.id)
                  && projectArray
                    .filter((project) => project.id === proyecto.id)
                    .map((project) => project.people
                      .sort((personA, personB) => personB.minutes - personA.minutes)
                      .map((person) => (
                        <tr key={person.id} className="details-row">
                          <td>{`${person.firstName} ${person.lastName}`}</td>
                          <td>{minutesToHours(person.minutes)}</td>
                          <td>{`${((person.minutes / project.totalMinutes) * 100).toFixed(2)} %`}</td>
                        </tr>
                      )))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default projectsTable;
