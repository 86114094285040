import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import './styles.scss';
import {
  endOfLastMonth,
  endOfLastWeek,
  labelFromUTC,
  startOfLastMonth,
  startOfLastWeek,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from '../../utils/dates';
import { getWorkedTimes, downloadWorkedTimesCsv } from '../../services/worked-times';
import WorkedTimesList from '../../components/worked-times-list';
import WorkedTimesProjectsStats from '../../components/worked-times-projects-stats';
import WorkedTimesInternalExternal from '../../components/worked-times-internal-external';
import Loader from '../../components/loader';
import Footer from '../../components/footer';
import NavBar from '../../components/nav-bar';

export default function Board() {
  const [filters, setFilters] = useState({
    dateFrom: labelFromUTC(startOfWeek(new Date()), 'YYYY-MM-DD'),
    dateTo: labelFromUTC(new Date(), 'YYYY-MM-DD'),
    mustSearch: true,
  });
  const [loading, setLoading] = useState(false);
  const [workedTimes, setWorkedTimes] = useState([]);

  function changeFilter(field, value) {
    const updatedFilters = { ...filters };
    updatedFilters[field] = value;
    setFilters(updatedFilters);
  }

  function search() {
    setLoading(true);
    setFilters({
      ...filters,
      mustSearch: false,
    });
    return getWorkedTimes(filters)
      .then((res) => {
        setWorkedTimes(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          return toast.error(`Hubo un error! ${error.response.data.code}`);
        }
        return toast.error('Mmm algo raro pasó, hablá con Lautaro');
      });
  }

  const downloadWorkedTimesList = useCallback(() => {
    const waitForDownload = downloadWorkedTimesCsv(filters);
    toast.promise(
      waitForDownload,
      {
        pending: 'Descargando archivo',
        success: 'Listo!',
        error: 'Algo anduvo mal',
      },
    );
  });

  function handlePredefinedFilter(period) {
    let fromDate;
    let toDate;
    switch (period) {
      case 'quarter':
        fromDate = labelFromUTC(startOfQuarter(new Date()), 'YYYY-MM-DD');
        toDate = labelFromUTC(new Date(), 'YYYY-MM-DD');
        break;
      case 'month':
        fromDate = labelFromUTC(startOfMonth(new Date()), 'YYYY-MM-DD');
        toDate = labelFromUTC(new Date(), 'YYYY-MM-DD');
        break;
      case 'lastmonth':
        fromDate = labelFromUTC(startOfLastMonth(new Date()), 'YYYY-MM-DD');
        toDate = labelFromUTC(endOfLastMonth(new Date()), 'YYYY-MM-DD');
        break;
      case 'lastweek':
        fromDate = labelFromUTC(startOfLastWeek(new Date()), 'YYYY-MM-DD');
        toDate = labelFromUTC(endOfLastWeek(new Date()), 'YYYY-MM-DD');
        break;
      case 'week':
      default:
        fromDate = labelFromUTC(startOfWeek(new Date()), 'YYYY-MM-DD');
        toDate = labelFromUTC(new Date(), 'YYYY-MM-DD');
        break;
    }
    setFilters({
      ...filters,
      dateFrom: fromDate,
      dateTo: toDate,
      mustSearch: true,
    });
  }

  function resultsBox() {
    if (loading) {
      return <Loader />;
    }
    if (!workedTimes || workedTimes.length === 0) {
      return (
        <div className="content-empty">
          <div className="items-empty">
            <img src="/assets/descarga.svg" alt="no data" />
            <h4>No hay datos</h4>
          </div>
        </div>
      );
    }
    return (
      <div className="results-box">
        <div className="left-column">
          <WorkedTimesProjectsStats items={workedTimes} />
          <WorkedTimesInternalExternal items={workedTimes} />
        </div>
        <div className="right-column">
          <WorkedTimesList items={workedTimes} download={downloadWorkedTimesList} />
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (filters.mustSearch) {
      search();
    }
  }, [filters]);

  return (
    <>
      <NavBar />
      <div className="board">
        <h1>HORAS LABORALES</h1>
        <div className="filters-box">
          <div>
            <h1 className="filters-title">Elegir periodo de tiempo</h1>
            <div className="predefined-options">
              <button type="button" onClick={() => handlePredefinedFilter('week')}>Esta semana</button>
              <button type="button" onClick={() => handlePredefinedFilter('lastweek')}>Semana pasada</button>
              <button type="button" onClick={() => handlePredefinedFilter('month')}>Este mes</button>
              <button type="button" onClick={() => handlePredefinedFilter('lastmonth')}>Mes pasado</button>
              <button type="button" onClick={() => handlePredefinedFilter('quarter')}>Este trimestre</button>
            </div>
          </div>
          <div className="date-range">
            <label htmlFor="dateFromInput">
              Desde:
              <input
                className="remove-border-right"
                type="date"
                id="dateFromInput"
                value={filters.dateFrom}
                onChange={(evt) => changeFilter('dateFrom', evt.target.value)}
              />
            </label>
            <label htmlFor="dateToInput">
              Hasta:
              <input
                type="date"
                id="dateToInput"
                value={filters.dateTo}
                onChange={(evt) => changeFilter('dateTo', evt.target.value)}
              />
            </label>
          </div>
          <button type="submit" onClick={search} className="btn-gral">Buscar</button>
        </div>
        <div className="clearfix" />
        {resultsBox()}
      </div>
      <Footer />
    </>
  );
}
