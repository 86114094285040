import React, { useState } from 'react';
import './styles.scss';
import { minutesToHours } from '../../utils/times';

export default function PeopleTable({ items, download }) {
  const [personRowsExpanded, setPersonRowsExpanded] = useState([]);
  const itemsByPerson = {};
  items.forEach((item) => {
    const personId = item.person.id;
    if (itemsByPerson[personId]) {
      itemsByPerson[personId].push(item);
    } else {
      itemsByPerson[personId] = [item];
    }
  });

  const totalMinutesByPerson = {};
  Object.keys(itemsByPerson).forEach((personId) => {
    const personItems = itemsByPerson[personId];
    const totalMinutes = personItems.reduce((acc, item) => acc + item.minutes, 0);
    totalMinutesByPerson[personId] = totalMinutes;
  });

  const handleClickDetail = (personId) => {
    setPersonRowsExpanded((prevExpandedRows) => {
      if (prevExpandedRows.includes(personId)) {
        return prevExpandedRows.filter((rowId) => rowId !== personId);
      }
      return [...prevExpandedRows, personId];
    });
  };

  const groupProjects = (personItems) => {
    const projects = {};
    personItems.forEach((item) => {
      const projectId = item.project.id;
      if (projects[projectId]) {
        projects[projectId].minutes += item.minutes;
      } else {
        projects[projectId] = {
          id: projectId,
          name: item.project.name,
          internal: item.project.internal,
          minutes: item.minutes,
        };
      }
    });
    const sortedProjects = Object.values(projects).sort((a, b) => b.minutes - a.minutes);

    return sortedProjects;
  };

  function sortPeopleByTotalHours() {
    const peopleArray = Object.values(itemsByPerson);
    peopleArray.sort((personA, personB) => {
      const totalMinutesA = personA.reduce((total, item) => total + item.minutes, 0);
      const totalMinutesB = personB.reduce((total, item) => total + item.minutes, 0);
      return totalMinutesB - totalMinutesA;
    });
    return peopleArray;
  }
  return (
    <div className="worked-times-list">
      <div className="table-container">
        <h2 className="title-table">
          Horas cargadas
          <button type="button" onClick={download} className="btn-gral small">
            Descargar
          </button>
        </h2>
        <table>
          <thead>
            <tr>
              <th>Persona</th>
              <th>Horas</th>
              <th>Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {sortPeopleByTotalHours(itemsByPerson).map((personItems) => {
              const personId = personItems[0].person.id;
              const totalMinutes = totalMinutesByPerson[personId];

              const projects = groupProjects(personItems);

              return (
                <React.Fragment key={`worked-times-item-${personId}`}>
                  <tr>
                    <td>
                      <div className="project-cell">
                        <button
                          type="button"
                          onClick={() => handleClickDetail(personItems[0].person.id)}
                          className="expand-button"
                        >
                          {personRowsExpanded.includes(personItems[0].person.id) ? '- ' : '+' }
                        </button>
                        <span className="person-name">{`${personItems[0].person.firstName} ${personItems[0].person.lastName}`}</span>
                      </div>
                    </td>
                    <td>
                      <span className="person-hours">{minutesToHours(totalMinutes)}</span>
                    </td>
                    <td>
                      <span className="person-average">
                        {`${((totalMinutes / (Object.values(totalMinutesByPerson)
                          .reduce((acc, minutes) => acc + minutes, 0)))
                        * 100).toFixed(2)}%`}
                      </span>
                    </td>
                  </tr>
                  {personRowsExpanded.includes(personItems[0].person.id) && (
                    projects.map((project) => (
                      <tr key={`worked-times-project-${project.id}`} className="details-row">
                        <td>
                          <div className="project-cell">
                            {project.internal ? (
                              <div className="project-tag project-internal" />
                            ) : (
                              <div className="project-tag project-external" />
                            )}
                            {project.name}
                          </div>
                        </td>
                        <td>{minutesToHours(project.minutes)}</td>
                        <td>
                          {`${((project.minutes / totalMinutes) * 100).toFixed(2)} %`}
                        </td>
                      </tr>
                    ))
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
