import { React } from 'react';
import './styles.scss';

export default function ProjectSelector({
  projects, selectProject, internal, currentProject,
}) {
  return (
    <select
      onChange={(evt) => selectProject(evt.target.value)}
      value={currentProject ? currentProject.id : 'select'}
    >
      <option value="select" className="placeholder">Elegir</option>
      {
        projects.map((project) => {
          return project.internal === internal && (
            <option value={project.id} key={`project-${project.id}`}>
              {project.name}
            </option>
          );
        })
    }
    </select>
  );
}
