import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import './styles.scss';
import { getPeople } from '../../services/people';
import { getProjects } from '../../services/projects';
import { saveWorkedTime } from '../../services/worked-times';
import { dayOfWeekFromUTC, labelFromUTC, startOfWeek } from '../../utils/dates';
import Loader from '../../components/loader';
import WeekHistory from '../../components/week-history';
import TimeSelector from '../../components/time-selector';
import ProjectSelector from '../../components/project-selector';
import NavBar from '../../components/nav-bar';
import { useAuth } from '../../hooks/auth';

export default function LoadWorkedTime() {
  const [projects, setProjects] = useState();
  const [textButton, setTextButton] = useState('Guardar');
  const [saving, setSaving] = useState(false);
  const [people, setPeople] = useState();
  const [entries, setEntries] = useState([]);
  const [personId, setPersonId] = useState();
  const [date, setDate] = useState(new Date());
  const [forceReloadWeekHistory, setForceReloadWeekHistory] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const { isLoggedIn } = useAuth();
  const lastWeekMonday = startOfWeek(new Date());
  lastWeekMonday.setDate(lastWeekMonday.getDate() - 7);

  let personFound;
  useEffect(() => {
    if (!isLoggedIn()) {
      <Navigate replace to="/login" />;
    }
    getPeople()
      .then((res) => {
        setPeople(res);
      });
  }, []);

  function checkDay() {
    if (date) {
      const dayOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      return dayOfWeek[dayOfWeekFromUTC(date)];
    }
    return '-';
  }

  useEffect(() => {
    getProjects()
      .then((res) => {
        setProjects(res);
      });
  }, []);

  useEffect(() => {
    if (people && localStorage.getItem('personId')) {
      personFound = people.find((person) => {
        if (person.id === parseInt(localStorage.getItem('personId'), 10)) {
          setPersonId(person.id);
          return person;
        }
        return null;
      });
    }
  }, [people]);

  function selectPerson(evt) {
    setPersonId(parseInt(evt.target.value, 10));
    personFound = people.find((person) => person.id === parseInt(evt.target.value, 10));
    localStorage.setItem('personId', personFound.id);
  }

  function selectedDay(evt) {
    setDate(evt);
  }

  function selectProject(projectId) {
    const selectedProject = projects.find((elem) => {
      return elem.id.toString() === projectId;
    });
    if (!selectedProject) {
      return;
    }
    if (selectedProject.id === currentProject?.id) {
      setCurrentProject(null);
    } else {
      setCurrentProject(selectedProject);
    }
    const isInEntries = entries.find((elem) => {
      return elem.projectId === selectedProject.id;
    });
    if (isInEntries) {
      return;
    }
    setCurrentProject(currentProject);

    const entryValue = {
      projectId: selectedProject.id,
      projectName: selectedProject.name,
      minutes: 0,
    };
    const newEntries = entries.map((elem) => elem);
    newEntries.push(entryValue);
    setEntries(newEntries);
  }

  function deleteProject(id) {
    const newEntries = entries.filter((entry) => entry.projectId !== id);
    setEntries(newEntries);
  }

  function changeMinutes(projectId, minutes) {
    const updatedEntries = entries.map((proj) => {
      if (projectId !== proj.projectId) {
        return proj;
      }
      return {
        projectId: proj.projectId,
        projectName: proj.projectName,
        minutes,
      };
    });
    setEntries(updatedEntries);
  }

  function submit() {
    setSaving(true);
    return saveWorkedTime({
      personId,
      date,
      entries: entries.map((entry) => {
        return {
          projectId: entry.projectId,
          minutes: entry.minutes,
        };
      }),
    })
      .then(() => {
        toast.success('Horas cargadas!');
        setEntries([]);
        setForceReloadWeekHistory(true);
        setCurrentProject(null);

        setTimeout(() => {
          setSaving(false);
          setForceReloadWeekHistory(false);
        }, 500);
      })
      .catch((error) => {
        setSaving(false);
        if (error.response) {
          return toast.error(`Hubo un error! ${error.response.data.code}`);
        }
        return toast.error('Mmm algo raro pasó, hablá con Lautaro');
      });
  }

  function getTotalMinutes() {
    let totalMinutes = 0;
    entries.forEach((proj) => {
      totalMinutes += proj.minutes;
    });
    return totalMinutes;
  }

  function renderTime() {
    let totalminutes = getTotalMinutes();
    let totalhours = 0;
    while (totalminutes >= 60) {
      totalminutes -= 60;
      totalhours += 1;
    }
    return { hours: totalhours, minutes: totalminutes };
  }

  function projectsQuant() {
    return entries.length;
  }

  function renderFirstOption() {
    if (localStorage.getItem('personId')) {
      personFound = people.find((person) => {
        if (person.id === parseInt(localStorage.getItem('personId'), 10)) {
          return person;
        }
        return null;
      });
    }

    if (personFound) {
      return (
        <option value={personFound.id} key={`person-${personFound.id}`}>
          {`${personFound.firstName} ${personFound.lastName}`}
        </option>
      );
    }

    return (<option value={null}>Elegir</option>);
  }

  if (!people || !projects) {
    return <Loader />;
  }

  return (
    <>
      <NavBar />
      <div className="load-worked-time">
        <div className="content-data-left">
          <h2>CARGA DE HORAS LABORALES</h2>
          <div className="box-form">
            <label htmlFor="personInput">
              Selecciona Nombre
              <select id="personInput" onChange={selectPerson}>
                {renderFirstOption()}
                {people.map((person) => {
                  if (personFound && personFound.id === person.id) {
                    return null;
                  }
                  return (
                    <option value={person.id} key={`person-${person.id}`}>
                      {`${person.firstName} ${person.lastName}`}
                    </option>
                  );
                })}

              </select>
            </label>
          </div>
          <div className="history-box">
            <WeekHistory
              dateFrom={lastWeekMonday}
              person={personId}
              force={forceReloadWeekHistory}
              selectDay={(dat) => selectedDay(dat)}
              changeTextButton={(text) => setTextButton(text)}
            />
            <WeekHistory
              person={personId}
              force={forceReloadWeekHistory}
              selectDay={(dat) => selectedDay(dat)}
              changeTextButton={(text) => setTextButton(text)}
            />
          </div>
        </div>
        <div className="project-data-right">
          <h3>
            {`Día: ${checkDay()} ${labelFromUTC(date, 'DD/MM')}`}
          </h3>
          <div className="grid-box-projects">
            <div>
              <div className="box-form">
                <div className="external-projects">
                  <span>Proyectos externos</span>
                  <ProjectSelector
                    projects={projects}
                    selectProject={(evt) => selectProject(evt)}
                    internal={Boolean(false)}
                  />
                </div>
                <div className="internal-projects">
                  <span>Proyectos internos</span>
                  <ProjectSelector
                    projects={projects}
                    selectProject={(evt) => selectProject(evt)}
                    internal={Boolean(true)}
                  />
                </div>
              </div>
            </div>
            <div className="project-info">
              <div className="project-time">
                {entries.length === 0 ? (<h2>No hay proyectos seleccionados</h2>)
                  : entries.map((entry) => {
                    return (
                      <TimeSelector
                        key={`time-sel-${entry.projectId}`}
                        label={entry.projectName}
                        onChange={(value) => { changeMinutes(entry.projectId, value); }}
                        deleteProject={() => deleteProject(entry.projectId)}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="clearfix" />
          <div className="last-box">
            <div className="resume">
              <h4>Total</h4>
              <div className="box-resume">
                Proyectos
                <span>{projectsQuant()}</span>
              </div>
              <div className="box-resume">
                Horas
                <span>{`${renderTime().hours || '00'}:${renderTime().minutes || '00'}`}</span>
              </div>
            </div>
            <div className="button-side">
              {!saving && <button type="button" className="submit-btn btn-gral" onClick={submit}>{textButton}</button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
